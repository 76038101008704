<template>
  <Stack
    v-if="messages?.length > 0 && image"
    direction="row"
    gap="none"
    justify="evenly"
    align="center"
    class="bg-mkm-yellow-default"
  >
    <div
      :class="[
        'relative max-h-[430px] lg:min-h-[500px]',
        'before:absolute before:z-10 before:top-none before:right-[-60px]  before:lg:right-[-90px]  before:bg-mkm-yellow-default before:w-[30%] before:h-[50%] before:skew-x-[30deg]',
        'after:absolute after:z-10 after:bottom-none after:right-[-60px] after:lg:right-[-90px]  after:bg-mkm-yellow-default after:w-[30%] after:h-[50%] after:skew-x-[-30deg]',
      ]"
    >
      <div
        :class="[
          'relative hidden lg:block',
          'before:absolute before:top-none before:left-[-60px] before:lg:left-[-90px] before:bg-mkm-yellow-default before:w-[30%] before:h-[50%] before:skew-x-[30deg]',
          'after:absolute after:bottom-none after:left-[-60px] after:lg:left-[-90px] after:bg-mkm-yellow-default after:w-[30%] after:h-[50%] after:skew-x-[-30deg]',
        ]"
      >
        <NuxtPicture
          :src="image"
          alt="Customer Testimonials"
          loading="lazy"
          :img-attrs="{
            class: 'w-[400px] lg:w-[580px] h-[430px] lg:h-[500px] object-cover object-[20%]',
          }"
        />
      </div>
    </div>

    <div class="w-full lg:max-w-[800px] text-charcoal-default my-xl z-20">
      <Heading size="md" class="text-center">
        {{ title }}
      </Heading>

      <LazySlider
        :slides-per-view="1"
        :draggable="true"
        :autoplay="false"
        always-show-arrows
        display-pagination-dots
        :items="messages"
        :with-dark-background="false"
      >
        <swiper-slide v-for="(message, index) in messages" :key="index">
          <Text class="mt-lg mb-sm text-center" size="md" weight="semi">
            {{ message.messageAuthor }}
          </Text>
          <div class="rich-text text-center px-2xl sm:pb-sm font-semibold" v-html="message.messagetext?.value" />
        </swiper-slide>
      </LazySlider>
    </div>
  </Stack>
</template>
<script setup lang="ts">
import type { Component, Page } from "@bloomreach/spa-sdk";
import { useCustomerTestimonials } from "../../composables/UseContent/useCustomerTestimonials";
import { SwiperSlide } from "swiper/vue";

const props = defineProps<{ component: Component; page: Page }>();
const { component, page } = toRefs(props);

const testimonials = computed(() => useCustomerTestimonials(page.value, component.value));

const title = computed(() => testimonials.value.title);
const image = computed(() => testimonials.value.image);
const messages = computed(() => testimonials.value.messages);
</script>
